.wrapper {
  & h2 {
    margin-bottom: var(--spacing-9);
  }
}

.promotionWrapper {
  background-color: var(--color-primary-700);
  color: var(--color-championship-white);
  display: flex;
  flex-direction: column-reverse;

  & a {
    color: var(--color-championship-white);
  }

  @media (--tablet-l) {
    flex-direction: row;
    height: 523px;
  }

  .textWrapper {
    padding: var(--spacing-5);

    @media (--tablet) {
      padding: var(--spacing-8) var(--spacing-5) var(--spacing-8) var(--spacing-8);
    }

    @media (--desktop) {
      padding: var(--spacing-10) 80px var(--spacing-10) var(--spacing-10);
    }

    .title {
      line-height: 48px;
      margin: 0;

      & strong {
        background: var(--color-secondary-500);
        color: var(--color-primary-700);
      }
      & p {
        margin: 0;
      }

      @media (--tablet) {
        line-height: 72px;

        & div {
          -webkit-line-clamp: 2;
        }
      }
    }

    .description {
      margin: 0;
      padding-top: var(--spacing-6);
      padding-bottom: var(--spacing-4);

      & a {
        color: var(--color-championship-white);
      }

      & div p {
        margin: 0;
      }

      @media (--tablet) {
        & div {
          -webkit-line-clamp: 2;
        }
      }
    }

    .bulletList {
      padding-bottom: var(--spacing-4);

      & div p {
        margin: 0;
        padding-bottom: var(--spacing-1);
      }
    }

    .bulletItem {
      display: flex;
      gap: var(--spacing-3);
      margin-bottom: var(--spacing-3);

      @media (--tablet) {
        & div {
          -webkit-line-clamp: 1;
        }
      }
    }

    .primaryCta {
      width: 100%;
      background-color: var(--color-championship-white);
      color: var(--color-primary-700);

      &:hover {
        background-color: var(--color-neutral-50);
      }
      @media (--tablet-l) {
        width: 272px;
      }
    }

    .secondaryCta {
      display: block;
      padding-bottom: var(--spacing-6);

      & button {
        color: var(--color-championship-white);
      }
    }

    .disclaimer {
      display: block;
      padding-top: var(--spacing-5);
      text-align: justify;

      @media (--tablet-l) {
        display: none;
      }
    }
  }

  .imageWrapper {
    width: 100%;
    position: relative;
    @media (--tablet-l) {
      max-width: 644px;
    }

    & img {
      position: relative !important;
      width: 100%;
      height: 100%;
      max-height: 240px;

      @media (--tablet-l) {
        max-height: none;
      }
    }

    .disclaimer {
      display: none;
      position: absolute;
      padding: var(--spacing-5);
      text-align: justify;

      @media (--tablet-l) {
        max-height: none;
        display: block;
      }
    }
  }

  .disclaimer {
    bottom: 0;
    text-align: left;
    text-align: justify;

    & div p {
      margin: 0;
    }
  }
}

.secondaryPromotionsWrapper {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  margin-top: var(--spacing-6);
  gap: var(--spacing-6);

  @media (--tablet) {
    flex-direction: row;
    max-height: 328px;
  }
}

.promotionWrapper.secondaryPromotion {
  flex: 1;
  justify-content: flex-start;
  background-color: var(--color-primary-100);
  color: var(--color-primary-700);

  @media (--tablet-l) {
    height: auto;
    justify-content: space-between;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-5) var(--spacing-10) var(--spacing-5) var(--spacing-5);
    height: 100%;
    width: 100%;

    @media (--desktop) {
      max-width: 332px;
      width: auto;
    }

    .title {
      line-height: 36px;

      @media (--tablet) {
        line-height: 43px;
      }
    }

    .description {
      padding-top: var(--spacing-4);

      @media (--tablet) {
        & div {
          -webkit-line-clamp: 3;
        }
      }
    }

    .secondaryCta {
      padding-bottom: var(--spacing-5);
      & button {
        color: var(--color-mica-blue-500);
      }
    }

    .primaryCta {
      & button {
        background-color: transparent;
        color: var(--color-mica-blue-500);
        border: 1px solid var(--color-mica-blue-500);

        &:hover {
          color: var(--color-primary-700);
          border-color: var(--color-primary-700);
        }

        @media (--tablet-l) {
          width: 100%;
        }
      }
    }
  }

  .imageWrapper {
    @media (--tablet) {
      display: none;
    }
    @media (--desktop) {
      max-width: 312px;
      display: block;
    }
  }
}

.promotionWrapper.secondaryPromotion.tertiaryPromotion {
  background-color: var(--color-mica-blue-500);
  color: var(--color-championship-white);

  .textWrapper {
    .secondaryCta {
      & button {
        color: var(--color-championship-white);
      }
    }

    .primaryCta {
      background-color: transparent;
      color: var(--color-championship-white);
      border: 1px solid var(--color-championship-white);

      &:hover {
        color: var(--color-neutral-100);
        border-color: var(--color-neutral-100);
      }
    }
  }
}

.truncateText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}

[data-tenant='rac'] {
  &&& {
    & .promotionWrapper {
      background-color: var(--color-rac-grey-10);
      color: var(--color-rac-grey-100);
    }

    & .textWrapper {
      & button {
        transition: 0.1s;
      }

      & .title {
        line-height: 48px;
        margin: 0;

        & strong {
          background: none;
          color: inherit;
        }
      }

      & .primaryCta {
        & button {
          width: 100%;
          border-color: var(--color-primary-500);
          color: var(--color-primary-500);

          &:hover {
            background-color: var(--color-primary-500);
            color: var(--color-championship-white);
          }
          @media (--tablet-l) {
            width: 272px;
          }
        }
      }

      & .secondaryCta {
        display: block;
        padding-bottom: var(--spacing-6);

        & button {
          color: var(--color-primary-500);
        }
      }
    }
  }
}
